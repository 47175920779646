import(/* webpackMode: "eager", webpackExports: ["ParallaxBackground"] */ "/vercel/path0/src/components/background/parallax-background.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContactForm"] */ "/vercel/path0/src/components/form/contact-form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AboutSection"] */ "/vercel/path0/src/components/sections/about-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ExperienceSection"] */ "/vercel/path0/src/components/sections/experience-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeroSection"] */ "/vercel/path0/src/components/sections/hero-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProjectsSection"] */ "/vercel/path0/src/components/sections/projects-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FloatingNav"] */ "/vercel/path0/src/components/shared/floating-nav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PageTransition"] */ "/vercel/path0/src/components/shared/page-transition.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ScrollToTop"] */ "/vercel/path0/src/components/shared/scroll-to-top.tsx");
